body,
html {
  width: 100%;
  height: 100%;

  margin: 0;
}

#container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #131B26;
  overflow-y: auto;
  padding: 30px 10px 10px 30px;
  font-family: 'Chakra Petch', sans-serif;
}

.text {

  font-weight: 500;
  letter-spacing: .1rem;
  text-transform: uppercase;
  font-size: 12px;
}

#vulcanoSettings {
  position: absolute;
  top: 10px;
  right: 0;
  /* bottom: 20px; */
  left: 0;
  width: 100%;
  /* background-color: #131B26; */
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #758EA3;
  font-size: 14px;
  gap: 10px;
  padding: 0 15px;
  font-weight: 500;
}

#vulcanoSettingsBottom {
  position: absolute;
  bottom: 10px;
  right: 0;
  /* bottom: 20px; */
  left: 0;
  width: 100%;
  /* background-color: #131B26; */
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-size: 14px;
  gap: 10px;
  padding: 0 15px;
  font-weight: 500;
}

.panel-header {
  position: absolute;
  top: 10px;
  right: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-size: 14px;
  gap: 10px;
  padding: 0 15px;
  font-weight: 500;
}

.panel {
  background-color: #192434;
  /*   margin: 30px; */
  padding: 24px;
  position: relative;
  border-radius: 15px;
  /* min-height: 150px; */

  display: flex;
  margin-right: 15px;
  margin-bottom: 15px;
  flex: 1;
  overflow: visible;
  justify-content: center;
}

.kpi-number {
  font-size: 40px;
  color: #fff;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 10px;
  transition: color 0.2s ease;
}

.kpi-number.kpi-number-active {
  color: #27fd27;
}

.kpi-subtitle,
.panel-title {
  color: #5E8FBA;
  line-height: 18px;
}

.panel-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 90px;
  text-overflow: ellipsis;
}

.panel-title-abs {
  position: absolute;
  top: 15px;
  right: 15px;
  left: 15px;
  z-index: 1;
}

.dashboard-holder {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.dashboard-row-big {
  flex: 2;
}

.dashboard-row {
  flex: 1;
  flex-direction: row;
  display: flex;
}

.main-dashboard-row {
  max-height: calc(100vh - 170px);
}

.main-dashboard-row.main-dashboard-row-full {
  max-height: calc(100vh);
}

.dashboard-row-bottom {
  /* height: 200px; */
}

.dashboard-cell {
  display: flex;
  height: 100%;
  position: relative;
}

.dashboard-right {
  flex: 1;
  display: flex;
  overflow-y: auto;
  position: relative;
}

.domain {
  stroke: #283B57 !important
}

.tick line {
  display: none;
}

.node-label {
  cursor: pointer;
}

.modal-container {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-bg {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(19, 27, 38, .6);
}

.modal-content {
  width: 700px;
  background: rgba(25, 36, 52, .75);
  border-radius: 15px;
  padding: 32px;
  position: relative;
  z-index: 100;
}

.tile-container {
  background: rgba(19, 27, 38, .6);
  padding: 10px 20px 10px 20px;
  flex: 1;
  margin: 0 10px 10px 0;
}

.tile-title {
  color: #5E8FBA;
  margin-bottom: 15px;
  font-size: 14px;

}

.tile-num {
  font-size: 24px;
}

.tile-row-container {
  flex: 1;
}

.tile-row {
  width: 100%;
  display: flex;
}

.modal-footer {
  margin-top: 16px;
  text-align: center;
}

.dashboard-cell-left {
  width: 60%;
}

.dashboard-cell-left.dashboard-cell-left-full {
  width: 100%;
}

.dashboard-cell-right {
  width: 40%;
  flex-direction: column;
}

@media (max-width:1000px) {

  .dashboard-cell-left,
  .dashboard-cell-right {
    width: 100%;
  }

  .dashboard-row {
    flex-direction: column;
  }

  .main-dashboard-row {
    max-height: inherit;
  }

  .panel {
    min-height: 300px;
  }

  .kpi-row {
    margin-bottom: 16px;
  }

  #container {
    padding: 16px;
  }

  .panel {
    margin-right: 0;
  }

  .dashboard-cell-left {
    min-height: calc(70vh - 16px);
  }

}

.overflow-hidden .panel {
  overflow: hidden;
}

.section-title {
  font-size: 15px;
  margin-bottom: 15px;
  color: #758EA3;
  font-weight: 700;
  letter-spacing: 0.05em;
  cursor: default;
}

.section-content {
  margin-bottom: 35px;
}

.switch-btn {
  display: inline-block;
  margin-right: 15px;
}

.switch-btn .switch-btn-title {
  margin-left: 7px;
  padding-top: 3px;
  cursor: default;
}

.section-content-row {
  margin-bottom: 10px;
}

.section-content-row .chakra-switch__thumb {
  background-color: #4790D1;
  /*#172736*/
}

.section-content-row .chakra-switch__track {
  background-color: #172736;
}

.section-content .chakra-slider__thumb {
  background-color: #4790D1;
}

.section-content .chakra-slider__track {
  background-color: hsl(209, 39%, 20%);
}

.section-content .chakra-slider__filled-track {
  background-color: #4790D1;
}

div.tooltip {
  position: absolute;
  text-align: center;
  padding: 8px;
  font: 14px sans-serif;
  background: #758EA3;
  border: 0px;
  border-radius: 8px;
  pointer-events: none;
  color: #172736;
}